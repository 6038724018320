@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "KaijuKingz";
  src: local("KaijuKingz"), url(/static/media/KaijuKingz.5c7a5ce9.ttf) format("truetype");
  font-weight: normal;
}

* {
  font-family: "Montserrat", sans-serif;
}
.color-nav {
  background-color: #1a1723;
  height: 69px;
}

.navbar-items {
  color: white !important;
  margin-left: 3rem;
  margin-right: 3rem;
  font-size: 1.3rem;
  text-decoration: none;
}
/* add 'on hover' get bigger to these items later */

.navLinks {
  display: flexbox;
  margin-left: auto;
  margin-right: auto;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
}

#navLogo {
  /* width:'200px',height:'auto' */
  width: 18rem;
  height: auto;
}

.socialLogos {
  height: 2.3rem;
}

.connectButton {
  padding: 1.2rem;
}

.rightside-nav{
  /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
  align-items: center;
  padding-right: 5rem;

}
.navbar-toggler-icon{
  background-image: url("/static/media/Mobil Menu Dots.4b314ee2.svg") !important;
}

/* MEDIA QUERIES */

/* 1366px and above: iPad PRO, HDready and FullHD desktop laptop monitors */
@media only screen and (min-width: 1366px) and (max-width: 1919px){
}
/* 1024px to 1365px: most Android tablets and iPads in landscape mode, older desktop/laptop monitors */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .connectButton{
    width: 8rem;
  }
}
/* 768px to 1023px: most Android tablets and iPads in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
/* 421px to 767px width: most smartphones in landscape mode */
@media only screen and (min-width: 421px) and (max-width: 767px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 1rem !important;
    margin-top: .25rem !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: 1rem !important;
    margin-top: .25rem !important;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
@media  (max-width: 320px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 10 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 10 !important;
    align-items: flex-end;
  }
}
/* 420px maximum width: cover all smartphones in portrait mode */
@media only screen and (min-width: 321px) and (max-width: 430px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 1rem !important;
    margin-top: .25rem !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: 1rem !important;
    margin-top: .25rem !important;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
@media  (max-width: 320px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 10 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 10 !important;
    align-items: flex-end;
  }
}
.loading-main {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
}

.loading-page-container{
  width: auto !important;
  height: 69rem !important;
}

.loading-page-gif{
  width: 100% !important;
  height: 100% !important;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  min-width: 37.5em;
}

