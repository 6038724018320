@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}
.color-nav {
  background-color: #1a1723;
  height: 69px;
}

.navbar-items {
  color: white !important;
  margin-left: 3rem;
  margin-right: 3rem;
  font-size: 1.3rem;
  text-decoration: none;
}
/* add 'on hover' get bigger to these items later */

.navLinks {
  display: flexbox;
  margin-left: auto;
  margin-right: auto;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
}

#navLogo {
  /* width:'200px',height:'auto' */
  width: 18rem;
  height: auto;
}

.socialLogos {
  height: 2.3rem;
}

.connectButton {
  padding: 1.2rem;
}

.rightside-nav{
  /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
  align-items: center;
  padding-right: 5rem;

}
.navbar-toggler-icon{
  background-image: url('../../assets/buttons/Mobil\ Menu\ Dots.svg') !important;
}

/* MEDIA QUERIES */

/* 1366px and above: iPad PRO, HDready and FullHD desktop laptop monitors */
@media only screen and (min-width: 1366px) and (max-width: 1919px){
}
/* 1024px to 1365px: most Android tablets and iPads in landscape mode, older desktop/laptop monitors */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .connectButton{
    width: 8rem;
  }
}
/* 768px to 1023px: most Android tablets and iPads in portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1023px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
/* 421px to 767px width: most smartphones in landscape mode */
@media only screen and (min-width: 421px) and (max-width: 767px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 1rem !important;
    margin-top: .25rem !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: 1rem !important;
    margin-top: .25rem !important;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
@media  (max-width: 320px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 10 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 10 !important;
    align-items: flex-end;
  }
}
/* 420px maximum width: cover all smartphones in portrait mode */
@media only screen and (min-width: 321px) and (max-width: 430px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 0 !important;
  }
  .navbar-items{
    margin-right: 1rem !important;
    margin-top: .25rem !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: 1rem !important;
    margin-top: .25rem !important;
    padding-right: 0 !important;
    align-items: flex-end;
  }
}
@media  (max-width: 320px){
  .connectButton{
    width: 8rem;
  }
  .navLinks{
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 5rem;
    align-items: flex-end;
    padding-right: 10 !important;
  }
  .navbar-items{
    margin-right: 0 !important;
  }
  .rightside-nav{
    /* style={{ alignItems: 'center', paddingRight:'5rem' }} */
    margin-left: 3rem;
    margin-right: auto;
    padding-right: 10 !important;
    align-items: flex-end;
  }
}