html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  min-width: 37.5em;
}
