.loading-main {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
}

.loading-page-container{
  width: auto !important;
  height: 69rem !important;
}

.loading-page-gif{
  width: 100% !important;
  height: 100% !important;
}